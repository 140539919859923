
// Write your custom style. Feel free to split your code to several files
//body {
  //color: blue;
//}
// @import url(../plugin/intl-tel-input/css/intlTelInput.min.css);
// @import url(../plugin/flatpickr/dist/flatpickr.min.css);

body{
  color: #1a1a1a;
  font-size: 0.9525rem;
}
.navbar-brand img {
  max-height: 90px;
}

.breadcrumb-item+.breadcrumb-item::before{
  width: 8px;
  height: 8px;
  display: inline-block;
  margin: 0.9rem 0.75rem;
}

@media (max-width: 1140px) {
  .header-bg-right {
    display: none;
  }
}

@media (max-width: 768px) {
  .header-bg-right {
    display: none;
  }
}

@media (max-width: 600px) {
  .header-bg-right {
    display: none;
  }
}

section{
  font-size: 0.9925rem;
}

.header-bg-right {
  width: 300px; 
  height: 300px;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.section{
  padding-top: 3rem;
  padding-bottom: 2rem ;
}

.bg-cartoon-1 {
  background-image: url("../img/vector/r01.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px;

}

.bg-cartoon-2 {
  background-image: url("../img/vector/a13.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px;
}

.bg-cartoon-3 {
  background-image: url("../img/vector/14.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px;
}

.bg-cartoon-4 {
  background-image: url("../img/vector/a1.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px;
}

.bg-cartoon-5 {
  background-image: url("../img/vector/contact.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px;
}

.bg-cartoon-6 {
  background-image: url("../img/vector/a17.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px;
}

.bg-cartoon-7 {
  background-image: url("../img/vector/product-tour.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px;
}

.bg-cartoon-8 {
  background-image: url("../img/vector/s1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px;
}

.bg-cartoon-9 {
  background-image: url("../img/vector/a12.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px;
}

.bg-cartoon-10 {
  background-image: url("../img/vector/features.webp");
  background-repeat: no-repeat;
  background-position: center;
  /*background-size: 300px;*/
  background-size: cover;
}

.bg-cartoon-11 {
  background-image: url("../img/vector/undraw_Terms_re_6ak4.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px;
}

.bg-cartoon-12 {
  background-image: url("../img/vector/undraw_privacy_protection_nlwy.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px;
}

.bg-cartoon-13 {
  background-image: url("../img/vector/undraw_smart_resize_wpn8.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px;
}

.bg-cartoon-14 {
  background-image: url("../img/vector/compare-plans.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px;
}

.text-indigo {
  color: #3775d6 !important
}

.form-group .iti {
  width: 100% !important;
}

.form-control {
  border-color: #acb2b8;
  color: #323d47;
}

.form-group.has-error .form-control {
  border-color: #dd4b39;
  box-shadow: none;
}
.form-control:focus {
  border-color: #acb2b8; 
}
// .btn-circle {
//   font-size: 1.7rem;
// }

.btn-actions {
  position: fixed;
  right: 36px;
  width: 54px;
  z-index: 1000;
  flex-direction: column;
  bottom: 2rem;
  display: flex;
}

.btn-actions .btn {
  opacity: 0.8;
}

.btn-actions .btn:hover {
  opacity: 1;
  transform: translateY(-2px);
  transition: .2s ease-out;
}

.btn-actions>.scroll-top {
  position: initial;
  right: unset;
  width: 54px;
  z-index: unset;
  bottom: unset;
  display: flex;
  opacity: 0.6;
}

.btn-actions .btn {
  margin-top: 1em;
}

.btn-actions .btn i.fa {
  font-size: 2em;
}

.btn-danger {
  color: #fff;
  background-color: #DF323D;
  border-color: #DF323D;
}
.btn-danger:hover {
  color: #fff;
  background-color: #D3222E;
  border-color: #D3222E;
}
a{
  color:#0655B0;
}
.btn-light {
  color: #373737;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.nav-link {
  color: #373737;
  font-weight: 400;
}
.text-default {
  color: #373737 !important;
}
.text-primary {
  color: #0655B0 !important;
}
.text-lighter {
  color: #535353 !important;
}



/*
	Responsive design for all devices
	https://gist.github.com/gokulkrishh/242e68d1ee94ad05f488
*/

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {

  .btn-actions {
    right: 15px !important;
    width: 25px;
    bottom: 2rem;
    margin-right: 0;
  }

  .btn-actions .btn {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }

  .btn-actions .btn i.fa {
    font-size: 1em !important;
  }



}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) {

  .btn-actions {
    right: 15px !important;
    width: 25px;
    bottom: 2rem;
    margin-right: 0;
  }

  .btn-actions .btn {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }

  .btn-actions .btn i.fa {
    font-size: 1em !important;
  }



}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
  .btn-actions {
    right: 15px !important;
    width: 25px;
    bottom: 2rem;
    margin-right: 0;
  }

  .btn-actions .btn {
    width: 34px;
    height: 34px;
    line-height: 34px;
  }

  .btn-actions .btn i.fa {
    font-size: 1.5em !important;
  }

}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) {
  .btn-actions {
    right: 45px !important;
    width: 44px;
    bottom: 2rem;
    margin-right: 0;
  }

  .btn-actions .btn {
    width: 60px;
    height: 60px;
    line-height: 44px;
  }

  .btn-actions .btn i.fa {
    font-size: 1.8em !important;
  }

}

.header-bg-right.bg-cartoon-9.bg-size-2x {
  width: 600px;
  height: 500px;
  background-position: initial;
  background-size: contain;
}

.navbar-brand img {
  max-height: 90px;
}

.bg-cartoon-faq {
  background-image: url("../img/faq1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px;
}

.grecaptcha-badge {
  visibility: hidden;
}

.msg .alert-heading {
  color: currentColor;
}
.block-number {
  text-align: center;
  font-weight: bold;
  font-size: 0.8125em;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #191919;
  transition: 0.3s ease-out;
}

.block-number:hover {
  opacity: 1;
}

.block-number::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 6px;
  margin-bottom: 2px;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 3px solid #191919;
}

.block-number a:hover,
.block-number a:focus {
  color: #191919;
}

.demo-section .main-content .section,
.demo-topbar .main-content .topbar,
.demo-navbar .main-content .navbar,
.demo-header .main-content .header,
.demo-footer .main-content .footer {
  margin: 30px 20px 100px;
  border: 1px solid #f5f6f7;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
}

.demo-topbar .main-content .topbar,
.demo-navbar .main-content .navbar {
  position: static;
  margin: 0;
}

.sample-blocks img {
  border-radius: 0.25rem;
}

.sample-blocks img.aos-animate {
  opacity: 0.5 !important;
}

.sample-blocks img:hover {
  opacity: 1 !important;
  transition: 0.7s !important;
}

.sample-blocks a {
  position: absolute;
}

.sample-blocks a:nth-child(1) {
  -webkit-animation: wobble 4s infinite;
  animation: wobble 4s infinite;
  right: 15px;
  bottom: -20px;
}

.sample-blocks a:nth-child(2) {
  -webkit-animation: wobble 8s infinite;
  animation: wobble 8s infinite;
  right: 320px;
  bottom: 30px;
}

.sample-blocks a:nth-child(3) {
  -webkit-animation: wobble 5s infinite;
  animation: wobble 5s infinite;
  right: 0px;
  bottom: 190px;
}

.sample-blocks a:nth-child(4) {
  -webkit-animation: wobble 6s infinite;
  animation: wobble 6s infinite;
  right: 280px;
  bottom: 180px;
}

.sample-blocks a:nth-child(5) {
  -webkit-animation: wobble 9s infinite;
  animation: wobble 9s infinite;
  right: 270px;
  bottom: 320px;
}

.sample-blocks a:nth-child(6) {
  -webkit-animation: wobble 7s infinite;
  animation: wobble 7s infinite;
  right: 20px;
  bottom: 370px;
}

.sample-blocks a:nth-child(7) {
  -webkit-animation: wobble 5s infinite;
  animation: wobble 5s infinite;
  right: 200px;
  bottom: 470px;
}

.sample-blocks a:nth-child(8) {
  -webkit-animation: wobble 8s infinite;
  animation: wobble 8s infinite;
  right: 10px;
  bottom: 470px;
}

.sample-blocks a:nth-child(9) {
  -webkit-animation: wobble 9s infinite;
  animation: wobble 9s infinite;
  right: 0px;
  bottom: 570px;
}

@-webkit-keyframes wobble {
  0% {
    transform: rotate(-1deg) translate(-5px, -5px);
  }

  50% {
    transform: rotate(1deg) translate(5px, 5px);
  }

  100% {
    transform: rotate(-1deg) translate(-5px, -5px);
  }
}

@keyframes wobble {
  0% {
    transform: rotate(-1deg) translate(-5px, -5px);
  }

  50% {
    transform: rotate(1deg) translate(5px, 5px);
  }

  100% {
    transform: rotate(-1deg) translate(-5px, -5px);
  }
}